import Store from '@/store'
import i18n from '@/i18n'

function getNotice (vm) {
    const singleApp = vm.applicationData[0]
    let noticeId
    if (typeof singleApp === 'undefined') {
        noticeId = 0
    } else {
        noticeId = singleApp.notice_id
    }
    const notice = Store.state.licenseRegistration.commonObj.noticeList.find(item => item.value === parseInt(noticeId))
    return (typeof notice === 'undefined') ? { meeting_date: '', fiscal_year_id: 0 } : { meeting_date: notice.meeting_date, fiscal_year_id: notice.fiscal_year_id }
}
function getFiscalYear (fiscalId, payload) {
    const orderData = Store.state.commonObj.fiscalYearList.find(item => item.value === parseInt(fiscalId))
    if (typeof orderData === 'undefined') {
        return ''
    }
    let order = orderData.sorting_order
    if (payload === 'previous') {
        // order = order
        order = order - 1
    }
    if ((payload === 'next')) {
        // order = order + 1
        order = order + 1
    }
    const fiscalyearData = Store.state.commonObj.fiscalYearList
    const fiscalYear = fiscalyearData.find(item => item.sorting_order === parseInt(order))
    if (typeof fiscalYear === 'undefined') {
        return ''
    } else {
        return (i18n === 'bn') ? fiscalYear.text_bn : fiscalYear.text_en
    }
}

function formateArray (array) {
    const filter = array.filter(item => item !== '')
    const uniqueArray = []
    filter.forEach(item => {
        if (uniqueArray.indexOf(item) === -1) {
            uniqueArray.push(item)
        }
    })
    return uniqueArray
}

export {
    getNotice, getFiscalYear, formateArray
}
