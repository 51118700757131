<template>
    <div>
        <b-tabs card>
            <b-tab :title="$t('li_step.applications')" active>
                <b-row>
                    <b-col md="6" v-if="applicantData.length > 0">
                        <div class="text-left">
                            <span v-if="applicantData[0].recommended_document" class="text-bold">{{ $t('boro_import.meeting_resulation') }} : <button type="button" class="btn btn-sm btn-success"><a target="_blank" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + applicantData[0].recommended_document"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a></button></span>
                            <ValidationProvider name="attachment" vid="attachment" rules="" v-else>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="attachment"
                                    >
                                    <template v-slot:label>
                                    {{ $t('boro_import.meeting_resulation') }}
                                    </template>
                                    <b-form-file
                                    id="attachment"
                                    v-model="fileData.attachment"
                                    v-on:change="onFileChange"
                                    ></b-form-file>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </b-col>
                    <b-col md="12">
                        <div class="text-right">
                            <b-button class="btn btn-sm btn-success mb-2" @click="pdfExport">
                                <i class="fa fa-print"></i>{{ $t('globalTrans.print') }}
                            </b-button>
                        </div>
                    </b-col>
                    <b-col md="12">
                        <div class="table-responsive" id="printArea">
                            <table class="table table-sm table-bordered main-table-it">
                                <tr>
                                    <th style="vertical-align: middle;width: 5%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                                    <th style="vertical-align: middle; width: 15%" class="text-center" rowspan="2">{{ $t('boro_import.company_details') }}</th>
                                    <th style="vertical-align: middle; width: 15%" class="text-center" rowspan="2"> {{ $t('boro_import.permittable_hybrid') }}</th>
                                    <th style="vertical-align: middle" class="text-center" colspan="4" > {{ local === 'bn' ? setFiscalYear() : '' }} {{ $t('boro_import.import_seed_amount') }} {{ local === 'en' ? setFiscalYear() : '' }}</th>
                                    <!-- <th style="vertical-align: middle" class="text-center" rowspan="2">{{ setFiscalYear() }} {{ $t('boro_import.production_seed_amount') }}</th>
                                    <th style="vertical-align: middle" class="text-center" rowspan="2">{{ $t('boro_import.abadi_jomi') }}</th> -->
                                </tr>
                                <tr>
                                    <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.af_1') }}</th>
                                    <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.a_line') }}</th>
                                    <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.r_line') }}</th>
                                    <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.seed_produce') }}</th>
                                </tr>
                                <slot v-for="(item, index) in applicantData">
                                    <tr v-for="(itm, indx) in item.add_more_1575" :key="indx + 10">
                                        <slot v-if="indx===0">
                                            <td class="text-center" :rowspan="item.add_more_1575.length">{{ $n(index+1) }}</td>
                                            <td :rowspan="item.add_more_1575.length">
                                                {{ local === 'bn' ? item.name_and_a____7450 : item.name_and_a____8831 }}, {{ local === 'en' ? item.addressen_5439 : item.addressbn_3295 }}
                                            </td>
                                        </slot>
                                        <td>{{ getStartData(itm).join(', \n') }}</td>
                                        <!-- <pre>{{ itm }}</pre>
                                        <pre>{{ getMiddleData(itm) }}</pre> -->
                                        <td class="text-center" v-for="(dataItem, key) in getMiddleData(itm)" :key="key+200">
                                            <span class="">{{ isNaN(dataItem) === false ? $n(dataItem) : dataItem }}</span>
                                            <input v-if="key !== 4 && key !== 3" class="form-control" type="text" @keyup="changInput(item.add_more_update[indx], $event, key)" :value="getUpData(item.add_more_update[indx], key)">
                                        </td>
                                        <!-- <slot v-if="indx===0">
                                            <td :rowspan="item.add_more_1575.length">{{ getEndData(item.add_more_1575).join(', ') }}</td>
                                        </slot> -->
                                    </tr>
                                </slot>
                                <tr>
                                    <td colspan="3" class="text-center">{{ $t('globalTrans.total') }}</td>
                                    <td class="text-center">{{ getFirstItemAmount(applicantData) }}</td>
                                    <td class="text-center">{{ getSecondItemAmount(applicantData) }}</td>
                                    <td class="text-center">{{ getThirdItemAmount(applicantData) }}</td>
                                    <td class="text-right">
                                        <button class="btn btn-primary btn-sm mr-2" v-on:click="SubmitData()">
                                            <i class="fas fa-check"></i>{{ $t('globalTrans.submit') }}
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab @click="loadComp" :title="$t('li_step.history_list')">
                <History :applicationData="applicantData" :key="updateComp"></History>
            </b-tab>
        </b-tabs>
    </div>
</template>
<style lang="less" scoped>
    .main-table-it th, td {
        padding: 2px !important;
        font-size: 13px;
    }
</style>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import common from '@/mixins/common'
import reportPdf from './pdf/pdf_b'
import History from './history/table-b'
import { getNotice, getFiscalYear, formateArray } from '../fiscalYear'

export default {
    mixins: [common],
    props: ['applicationData'],
        components: {
        History
    },
    data () {
        return {
            lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
            fileData: {
                attachment: '',
                applicationData: '',
                type: 1
            },
            updateComp: 1,
            applicantData: []
        }
    },
    created () {
        this.applicationData.forEach(item => {
            if (item.add_more_1575.length > 0) {
                this.applicantData.push(item)
            }
        })
    },
    computed: {
        local: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        setFiscalYear () {
           const fiscalId = getNotice(this).fiscal_year_id
           return getFiscalYear(fiscalId, 'current')
        },
        loadComp () {
            this.updateComp = this.updateComp + 1
        },
        getFirstItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 3) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getSecondItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 4) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getThirdItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 5) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        onFileChange (e) {
            this.getBase64(e.target.files[0]).then(res => {
                this.fileData.attachment = res
            })
        },
        getBase64 (file) {
            return new Promise(function (resolve, reject) {
                const reader = new FileReader()
                let imgResult = ''
                reader.readAsDataURL(file)
                reader.onload = function () {
                    imgResult = reader.result
                }
                reader.onerror = function (error) {
                    reject(error)
                }
                reader.onloadend = function () {
                    resolve(imgResult)
                }
            })
        },
        getStartData (item) {
            const array = []
            Object.values(item).forEach((itm, index) => {
                if (index === 0 || index === 1 || index === 2) {
                    const val = isNaN(itm) === false ? this.$n(itm) : itm
                    array.push(val)
                }
            })
            return array
        },
        getMiddleData (item) {
            return Object.values(item).filter((itm, index) => index !== 0 && index !== 1 && index !== 2 && index !== 7 && index !== 8)
        },
        getMiddleDataNew (item) {
            return Object.values(item).filter((itm, index) => index !== 0 && index !== 1 && index !== 2 && index !== 7)
        },
        getEndData (item) {
             const data = item.map(item => {
                return item.Upazilla
            })
            return formateArray(data)
        },
         async SubmitData () {
            this.$swal({
                title: this.$t('globalTrans.recommendentChangeMsg'),
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                focusConfirm: false
            }).then((resultSwal) => {
                if (resultSwal.isConfirmed) {
                this.confirmData()
                }
            })
        },
        async confirmData () {
            this.fileData.applicationData = this.applicantData
            let result = null
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            result = await RestApi.postData(licenseRegistrationServiceBaseUrl, '/step/boro-import/update-recommendation', this.fileData)
                if (result.success) {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                    this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
                    this.$toast.success({
                    title: 'Success',
                    message: 'Data saved successfully',
                    color: '#D6E09B'
                    })
                this.$emit('tableUpdate', true)
                }
            },
        getUpData (item, index) {
            const middleData = this.getMiddleData(item)
            return middleData[index]
        },
        changInput (item, event, keyInex) {
            const key = this.getIndexToKey(item, keyInex)
            item[key] = event.target.value
            return item[key]
        },
        getIndexToKey (item, myIndex) {
            const middleKeys = Object.keys(item).filter((itm, index) => index !== 0 && index !== 1 && index !== 2 && index !== 7)
            return middleKeys[myIndex]
        },
        pdfExport () {
            const reportTitle = this.$t('externalUserIrrigation.money_receipt_title')
            reportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, this.$route.params.id, reportTitle, this.columns, this.applicantData, this)
        }
    }
  }
</script>
