<template>
    <b-row>
        <b-col md="12">
            <div class="text-right">
                <b-button class="btn btn-sm btn-success mb-2" @click="printDiv">
                    <i class="fa fa-print"></i>{{ $t('globalTrans.print') }}
                </b-button>
            </div>
            <div class="table-responsive" id="printArea">
                <b-overlay :show="loadIt">
                    <table class="table table-sm table-bordered main-table-it" style="width:100%">
                        <tr>
                            <th style="vertical-align: middle; width: 5%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                            <th style="vertical-align: middle" class="text-center" rowspan="2">{{ $t('boro_import.company_details_a') }}</th>
                            <th style="vertical-align: middle" class="text-center" colspan="11" > {{ $t('boro_import.in_the_last') + ' ' + setFiscalYear() + ' ' +$t('boro_import.season') }}  </th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.variety_name') }}</th>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.meeting_date') }}</th>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.meeting_number') }}</th>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.varity_life_time') }}</th>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.lifetime_details_tab_1') }}</th>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.import_details') }}</th>
                            <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.actual_import_details') }}</th>
                            <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.local_production') }}</th>
                            <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.sell_amount') }}</th>
                            <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.un_sell_amount') }}</th>
                            <th style="vertical-align: middle" class="text-center" >{{ $t('boro_import.comment') }}</th>
                        </tr>
                        <slot v-for="(item, index) in applicantData">
                            <tr v-for="(itm, indx) in item.add_more" :key="indx + 10">
                                <slot v-if="indx===0">
                                    <td class="text-center" :rowspan="item.add_more.length">{{ $n(index+1) }}</td>
                                    <td :rowspan="item.add_more.length">
                                        {{ (local === 'bn' ? (item.name_and_a____7450) : (item.name_and_a____8831)) + ', '}}
                                        {{ local === 'bn' ? item.addressbn_3295 : item.addressen_5439 }}
                                        ({{ item.mobile_no__1597 | mobileNumber }}{{ ', ' }}
                                        {{ item.email___3403 }})
                                    </td>
                                </slot>
                                <td class="text-center" v-for="(dataItem, key) in itm" :key="key+200+'broff'">
                                <slot v-if="parseInt(dataItem) === 0 || !dataItem">
                                        -
                                    </slot>
                                    <slot v-else>
                                        {{ isNaN(dataItem) === false ? $n(dataItem) : dataItem }}
                                    </slot>
                                </td>
                            </tr>
                        </slot>
                        <tr>
                            <td colspan="4" class="text-center">{{ $t('globalTrans.total') }}</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center">{{ getSecondItemAmount(applicantData) }}</td>
                            <td class="text-center">{{ getThirdItemAmount(applicantData) }}</td>
                            <td class="text-center">{{ getFourthItemAmount(applicantData) }}</td>
                            <td class="text-center">{{ getFiftyItemAmount(applicantData) }}</td>
                            <td class="text-center">{{ getSixthItemAmount(applicantData) }}</td>
                            <td></td>
                        </tr>
                    </table>
                </b-overlay>
            </div>
        </b-col>
    </b-row>
</template>
<style lang="less" scoped>
    .main-table-it th, td {
        padding: 2px !important;
        font-size: 13px;
    }
</style>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import common from '@/mixins/common'
import { getNotice, getFiscalYear, formateArray } from '../fiscalYear'
export default {
    mixins: [common],
    props: ['applicationData'],
    data () {
        return {
            loadIt: false,
            applicantData: []
        }
    },
    created () {
        this.applicationData.forEach(item => {
            if (item.add_more.length > 0) {
                this.applicantData.push(item)
            }
        })
    },
    computed: {
        local: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        async printDiv () {
            this.loadIt = true
            const params = {
                service_id: 22,
                formId: 38,
                noticeId: 2,
                formStepId: 46,
                step_id: 80,
                type: 'pdf',
                local: this.$i18n.locale,
                fiscalYear: this.setFiscalYear(),
                table: 'tab_a',
                admin: true
            }
            try {
                const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, '/step/boro-import/applications', params)
                var blob = new Blob([result], { type: 'application/pdf' })
                var url = window.URL.createObjectURL(blob)

                // Create an anchor element for direct download
                var a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = 'table-a.pdf' // Set the desired filename for the download

                // Trigger a click event on the anchor element to initiate the download
                document.body.appendChild(a)
                a.click()

                // Clean up the anchor element and URL object
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)

                this.loadIt = false
            } catch (error) {
                this.loadIt = false
            }
        },
        setFiscalYear () {
           const fiscalId = getNotice(this).fiscal_year_id
           return getFiscalYear(fiscalId, 'previous')
        },
        getFirstItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 4) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getSecondItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 5) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getThirdItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 6) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getSixthItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 9) {
                            if (itm) {
                                total = total + parseFloat(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            if (parseInt(total) === 0) {
                return '-'
            }
            return this.$n(total)
        },
        getFiftyItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 8) {
                            if (itm) {
                                total = total + parseFloat(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getFourthItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 7) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getStartData (item) {
            const array = []
            Object.values(item).forEach((itm, index) => {
                if (index === 0 || index === 1) {
                    const val = isNaN(itm) === false ? this.$n(itm) : itm
                    array.push(val)
                }
            })
            return array
        },
        getSecondData (item) {
            const array = []
            Object.values(item).forEach((itm, index) => {
                if (index === 2 || index === 3) {
                    const val = isNaN(itm) === false ? this.$n(itm) : itm
                    array.push(val)
                }
            })
            return array
        },
        getMiddleData (item) {
            return Object.values(item).filter((itm, index) => index !== 0 && index !== 1 && index !== 2 && index !== 3 && index !== 8)
        },
        getEndData (item) {
            const data = item.map(item => {
                return item.Comments
            })
            return formateArray(data)
        }
    }
  }
</script>
