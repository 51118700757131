<template>
    <b-row>
        <b-col md="12">
            <div class="table-responsive" id="printArea">
                <!-- <pre>{{ recommendedData }}</pre> -->
                <table class="table table-sm table-bordered main-table-it" style="width:100%">
                    <tr>
                        <th style="vertical-align: middle;width: 5%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                        <th style="vertical-align: middle; width:10%" class="text-center" rowspan="2">{{ $t('boro_import.company_details') }}</th>
                        <th style="vertical-align: middle; width:10%" class="text-center" rowspan="2"> {{ $t('boro_import.permittable_hybrid') }}</th>
                        <th style="vertical-align: middle" class="text-center" colspan="3" >{{ $t('boro_import.import_seed_amount') }}</th>
                        <!-- <pre> {{recommendedData}} </pre> -->
                        <th v-for="(data, key, index) in recommendedData" :key="index" style="vertical-align: middle" class="text-center" colspan="3" >
                            <span v-if="userLoad">Loading...</span>
                            <span v-else>{{ getUserInfo(key) }}</span>
                        </th>
                    </tr>
                    <tr>
                        <th :key="index" style="vertical-align: middle; width: 10%"> {{ $t('boro_import.af_1') }}</th>
                        <th :key="index" style="vertical-align: middle; width: 10%"> {{ $t('boro_import.a_line') }}</th>
                        <th :key="index" style="vertical-align: middle; width: 10%"> {{ $t('boro_import.r_line') }}</th>
                        <slot v-for="(data, key, index) in recommendedData">
                            <th :key="index" style="vertical-align: middle; width: 10%"> {{ $t('boro_import.af_1') }}</th>
                            <th :key="index" style="vertical-align: middle; width: 10%"> {{ $t('boro_import.a_line') }}</th>
                            <th :key="index" style="vertical-align: middle; width: 10%"> {{ $t('boro_import.r_line') }}</th>
                        </slot>
                    </tr>
                    <slot v-for="(item, index) in applicationData">
                        <tr v-for="(itm, indx) in item.add_more_1575" :key="indx + 10">
                            <slot v-if="indx===0">
                                <td class="text-center" :rowspan="item.add_more_1575.length">{{ $n(index+1) }}</td>
                                <td :rowspan="item.add_more_1575.length">
                                    {{ local === 'bn' ? item.name_and_a____7450 : item.name_and_a____8831 }}, {{ local === 'bn' ? item.addressen_5439 : item.addressbn_3295 }}
                                </td>
                            </slot>
                            <td>{{ getStartData(itm).join(', \n') }}</td>
                            <td class="text-center" v-for="(dataItem, key) in getMiddleData(itm)" :key="key+200">
                                <span class="">{{ isNaN(dataItem) === false ? $n(dataItem) : dataItem }}</span>
                            </td>
                            <slot v-for="(data2) in recommendedData">
                                <slot>
                                    <td class="text-center" v-for="(it, key) in getMiddleData(data2[index].add_more_update[indx])" :key="key">
                                        <span class="">{{ isNaN(it) === false ? $n(it) : it }}</span>
                                    </td>
                                </slot>
                            </slot>
                        </tr>
                    </slot>
                    <tr>
                        <td colspan="3" class="text-center">{{ $t('globalTrans.total') }}</td>
                        <td class="text-center">{{ getFirstItemAmount(applicationData) }}</td>
                        <td class="text-center">{{ getSecondItemAmount(applicationData) }}</td>
                        <td class="text-center">{{ getThirdItemAmount(applicationData) }}</td>
                        <slot v-for="data2 in recommendedData">
                            <td class="text-center" v-for="(it, key) in getTamountData(data2)" :key="key">
                                <span class="">{{ it }}</span>
                            </td>
                        </slot>
                    </tr>
                </table>
            </div>
        </b-col>
    </b-row>
</template>
<style lang="less" scoped>
    .main-table-it th, td {
        padding: 2px !important;
        font-size: 13px;
    }
</style>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { boroUpdatedRecommendationList } from '../../../../../api/routes'
export default {
    props: ['applicationData'],
    data () {
        return {
            recommendedData: '',
            userList: [],
            userData: [],
            userLoad: false
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        local: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        getTamountData (item) {
            const total = []
            let total1 = 0
            let total2 = 0
            let total3 = 0
            item.forEach(data => {
                data.add_more_update.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (itm > 0) {
                            if (index === 3) {
                                total1 = total1 + parseInt(itm)
                            }
                            if (index === 4) {
                                total2 = total2 + parseInt(itm)
                            }
                            if (index === 5) {
                                total3 = total3 + parseInt(itm)
                            }
                        }
                    })
                })
            })
            total[0] = this.$n(total1)
            total[1] = this.$n(total2)
            total[2] = this.$n(total3)
            return total
        },
        getFirstItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 3) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getSecondItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 4) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getThirdItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 5) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getUserInfo (key) {
            const user = this.userData.find(item => item.id === parseInt(key))
            if (typeof user !== 'undefined') {
                return this.$i18n.locale === 'bn' ? user.name_bn : user.name
            } else {
                return ''
            }
        },
        loadUser () {
            this.userLoad = true
            RestApi.getData(authServiceBaseUrl, '/auth/portal/get-users', { user_ids: JSON.stringify(this.userList) }).then(response => {
                if (response.success) {
                    this.userData = response.data
                }
                this.userLoad = false
            })
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(licenseRegistrationServiceBaseUrl, boroUpdatedRecommendationList, { type: 1 }).then(response => {
                if (response.success) {
                    const tmpRecom = {}
                    Object.keys(response.data).forEach(item => {
                        const singUp = response.data[item].map(itm => {
                            return Object.assign(itm, {
                                add_more_update: JSON.parse(itm.add_more_update)
                            })
                        })
                        tmpRecom[item] = singUp
                    })
                    this.recommendedData = tmpRecom
                    this.$store.dispatch('setList', response.data)
                    const userList = []
                    Object.keys(response.data).forEach(key => {
                        userList.push(parseInt(key))
                    })
                    this.userList = userList
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                this.loadUser()
            })
        },
        getStartData (item) {
            const array = []
            Object.values(item).forEach((itm, index) => {
                if (index === 0 || index === 1 || index === 2) {
                    const val = isNaN(itm) === false ? this.$n(itm) : itm
                    array.push(val)
                }
            })
            return array
        },
        getMiddleData (item) {
            return Object.values(item).filter((itm, index) => index !== 0 && index !== 1 && index !== 2 && index !== 6 && index !== 7 && index !== 8)
        },
        getEndData (item) {
            return item.map(item => {
                return item.Upazilla
            })
        }
    }
  }
</script>
